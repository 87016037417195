import { defineStore } from "pinia";
import RestApiService from "@/api/restapi.service";
import { Pagination } from "@/types/Pagination";
import { Customer } from "@/types/Customer";
import { Feature, FeatureCollection } from "@/types/Feature";

export const useFeatureStore = defineStore({
  id: "features",
  state: () => ({
    errors: null,
    loading: false,
    featureList: {} as FeatureCollection,
    feature: {} as Feature,
  }),
  getters: {},
  actions: {
    async fetchFeatureList(
      pagination: Pagination
    ): Promise<Array<Feature> | Array<string>> {
      this.loading = true;
      const data = await RestApiService.get("/features", pagination)
        .then(({ data }) => {
          this.featureList = data;
          return data;
        })
        .catch(({ response }) => {
          this.errors = response.data.errors;
        });
      this.loading = false;
      return data;
    },

    async fetchFeature(id: number): Promise<Feature> {
      this.loading = true;
      const data = await RestApiService.get(`/features/${id}`)
        .then(({ data }) => {
          this.feature = data;
          return data;
        })
        .catch(({ response }) => {
          this.errors = response.data.errors;
        });
      this.loading = false;
      return data;
    },

    async createFeature(feature: Feature): Promise<Feature> {
      this.loading = true;
      const data = await RestApiService.post(`/features`, feature)
        .then(({ data }) => {
          return data;
        })
        .catch(({ response }) => {
          this.errors = response.data.errors;
        });
      this.loading = false;
      return data;
    },

    async updateFeature(feature: Feature) {
      this.loading = true;
      const data = await RestApiService.update(
        "/features",
        feature.id,
        feature
      );
      this.errors = data["errors"];
      this.loading = false;
      return data;
    },

    async deleteFeature(id: number) {
      this.loading = true;
      const data = await RestApiService.delete(`/features/${id}`);
      this.errors = data["errors"];
      return data;
    },

    async updateCustomerFeatures(customer: Customer) {
      this.loading = true;
      const data = await RestApiService.update(
        "/customers",
        customer.key,
        customer
      );
      this.errors = data["errors"];
      this.loading = false;
      return data;
    },
  },
});
